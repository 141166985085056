const trentinoConfig = {
  general: {
    pubstack: true,
    preloadedFiles: [
      {
        link: 'https://boot.pbstck.com/v1/adm/02cf7fe0-7795-448a-b918-bcbceb0393f71',
        async: true,
      },
    ],
  },
}

export default trentinoConfig
