const altoAdigeConfig = {
  general: {
    pubstack: true,
    preloadedFiles: [
      {
        link: 'https://boot.pbstck.com/v1/adm/fa84a06f-82e5-4617-b654-c56845443f8a',
        async: true,
      },
    ],
  },
}

export default altoAdigeConfig
