import isServerSide from '../../../../modules/is-server-side'
import slcOnLoad from '../../modules/slc-on-load'

const wattenOrgConfig = {
  general: {
    pubstack: true,
    userData: {
      email: () => {
        if (isServerSide()) {
          return
        }
        if (window.spieleremail && window.spieleremail !== 'null') {
          return window.spieleremail
        }
      },
    },

    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () =>
          slcOnLoad({
            id: 'SLC-HXKQGRLA',
            enableTargets: true,
            prefixUserId: 'WAT',
          }),
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
      {
        link: 'https://www.googletagmanager.com/gtag/js?id=G-BZVJB9M5XZ',
        async: true,
      },
      {
        link: 'https://boot.pbstck.com/v1/adm/26e13ae2-7e58-41c1-b320-5d6d10cec3ab',
        async: true,
        onload: () => {
          console.log('Pubstack done')
        },
        idLoadOnce: 'pubstack-script',
      },
    ],
  },
}

export default wattenOrgConfig
