// For full documentation see
// https://github.com/eBayClassifiedsGroup/react-advertising/wiki/Configuration#size-mappings

// Config for Südtirol News
import settingsSTN from '../../../BundleSTNWidgets/settings'
import getCookieContent from '../../modules/get-cookie-content'
import slcOnLoad from '../../modules/slc-on-load'

// const customCss = {
//   sky: `
//     ${handlerMediaqueryTag().create({ max: 1320, min: 769 })} {
//       max-height: 90px;
//     }
//     ${handlerMediaqueryTag().create({ max: 768 })} {
//       max-height: 50px;
//     }
//   `,
//   rec: `
//     ${handlerMediaqueryTag().create({ max: 768 })} {
//       max-height: 400px;
//     }
//   `,
// }

const stnConfig = {
  general: {
    pubstack: true,
    hiddenOptions: settingsSTN(),

    userData: {
      email: () => getCookieContent({ cookieName: 'stnews-user', cookieObjectProperty: 'email' }),
    },
    preloadedFiles: [
      {
        link: 'https://connect.suedtirol.live/slc.js',
        async: true,
        onload: () => {
          slcOnLoad({
            id: 'SLC-MOT7XPTV',
            enableTargets: true,
            prefixUserId: 'STN',
          })
          window.addEventListener('message', function (event) {
            if (!event.origin.endsWith('.pinpoll.com') || !event.data || event.data.event != 'vote') {
              return
            }
            console.log('dbg_DT')
            if (event.data && event.data.audiences) {
              console.log('dbg_DT_A')
              const interestIds = event.data.audiences.interests.map((interest) => interest.id)
              if (interestIds.length > 0) {
                console.log('dbg_DT_A_SND')
                SLC('pageEvent', 'pinpoll', 'vote', '', '', JSON.stringify(interestIds))
              }
            }
          })
        },
      },
      {
        link: 'https://boot.pbstck.com/v1/adm/b059ff2b-d40e-4ab0-b051-83eb31a1c37d',
        async: true,
        onload: () => {
          console.log('Pubstack done')
        },
        idLoadOnce: 'pubstack-script',
      },
      {
        link: 'https://launchpad-wrapper.privacymanager.io/8635aa92-be83-46e4-945a-975b0f76a243/launchpad-liveramp.js',
        async: true,
      },
      {
        link: 'https://cdn.pinpoll.com/sdk.js',
        async: false,
        onload: () => {
          const sdk = new PinpollSdk()
          if (sdk) {
            const options = {
              tree: false,
              removeRootLevel: 'properties',
              onlyLabels: 'de',
            }
            sdk
              .getAudiences(options)
              .then(function (audiences) {
                if (audiences && audiences.interests) {
                  let escapedInterests = []
                  audiences.interests.forEach((int) => {
                    const newInt = int.replace(',', '')
                    escapedInterests.push(newInt)
                  })
                  // Make sure that googletag.cmd exists.
                  window.googletag = window.googletag || {}
                  googletag.cmd = googletag.cmd || []
                  googletag.cmd.push(() => {
                    googletag.pubads().setTargeting('stn_dmp_interests', escapedInterests)
                  })
                }
              })
              .catch(function (error) {
                console.error(error)
              })
          }
        },
      },
    ],
  },
}

export default stnConfig
