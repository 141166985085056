const ladigeConfig = {
  general: {
    pubstack: true,
    preloadedFiles: [
      {
        link: 'https://boot.pbstck.com/v1/adm/fdce1493-25be-4fb2-a4a9-409007be161d',
        async: true,
      },
    ],
  },
}

export default ladigeConfig
