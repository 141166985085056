import consoleLogger from '../../../../modules/console-logger'

const [consoleLog] = consoleLogger('AdsLog')

const slcGetTargets = ({ onGetSLCTargets = () => {} }) => {
  const currentMinutes = Math.floor(Date.now() / 1000 / 60)
  const slcTargetsMaxAge = 30
  const slcTargetsString = window.localStorage.getItem('SLCTargets')
  let askForNewTargets = true

  if (slcTargetsString) {
    const slcTargets = JSON.parse(slcTargetsString)
    const lastUpdate = slcTargets.lastupdate ?? 0

    if (lastUpdate + slcTargetsMaxAge >= currentMinutes) {
      onGetSLCTargets(slcTargets)
      askForNewTargets = false
    }
  }

  if (askForNewTargets) {
    consoleLog('SLC', 'ask for new targets', 'SLC')
    SLC(
      'gs',
      (response) => {
        consoleLog('SLC new targets: ', response, 'SLC')
        response.lastupdate = currentMinutes
        window.localStorage.setItem('SLCTargets', JSON.stringify(response))
        onGetSLCTargets(response)
      },
      (error) => {
        consoleLog('SLC error: ', error, 'SLC')
      },
    )
  }
}

export default slcGetTargets
